import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo" 
import DirectoryCategories from "../../components/content/categories" 
import GridIcons from "../../components/content/gridicons" 

const BusinessPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query LabsPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="Labs" />
          <div className="jumbotron page-header">
            <div className="container">
              <h1 className="display-32">Labs</h1>
              <h2 className="display-32">
                Get digital solutions and insights today.{" "}
                <span style={{ color: "#007bff" }}>Focus on what matters.</span>
              </h2>
              <p>
                Your customers expect better, smarter and safer and more
                intelligence, services and solutions to drive results.
              </p>{" "}
           
           
            </div>
          </div>

          
          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  {/* <h3>
                    We ready to help
                    </h3> */}

                  <h3> ACCELERATING INNOVATION.</h3>

                  <p> 
                  At UVISION, we are constantly pushing the envelope when it
                      comes to innovation as we combine multiple technology
                      platforms, state of the art computing, engineering &amp;
                      design to develop new innovative solutions to improve
                      people’s lives &amp; accelerate transformative results for
                      organizations.
                    <br />
                    <br />
                  </p>
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="mb40 mb-xs-24">Our areas of interest</h2>
                    </div>
                  </div>

                  <div className="row">
                
                
                  <div className="col-md-12">
                      <span className="lead">
                        <span
                        >
                          Artificial Intelligence, Cognitive Computing
                        </span>
                        <br />
                        <span
                        >
                          Machine Learning,&nbsp;Machine Intelligence
                          <br />
                          Big Data, Data Engineering &amp; Data Science
                          <br />
                          Analytics, Data Visualization, Geospatial
                        </span>
                        <br />
                        <span
                        >
                          IoT, Sensors, Computer Vision
                        </span>
                        <br />
                        <span
                        >
                          Simulations, HPC Computing, Supercomputing
                        </span>
                        <br />
                        <span
                        >
                          Virtual, Augmented &amp; Mixed Reality
                        </span>
                        <br />
                        <span
                        >
                          Desktop, Web, Mobile &amp; Enterprise Software
                        </span>
                        <br />
                        <span
                        >
                          Cloud, SaaS, PaaS, IaaS Platforms
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <a className="btn btn-primary  btn-sm" href="/why">
                   Why UVision?
                </a>


              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <a
                  className="btn btn-outline-primary mb64 "
                  href="/lets-talk/?service=solutions"
                >
                  Learn More
                </a>
                {/* <a
                                  className="btn btn-outline-primary mb64"
                                >
                                  Learn More
                                </a> */}
                <div className={"container d-none"}>
                  <div className={"call-to-action__content2"}>
                    {/* <h2>We ready to help</h2> */}
                    <p>
                      {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                      {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                    </p>
                    {/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                  </div>

                  {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                </div>
              </div>
            </div>
          </section>




          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  {/* <h3>
                    We ready to help
                    </h3> */}

                  <h3> PRODUCTS</h3>

                  <p> 
                  UVISION is a company that creates new products and companies. We focus on just a few products at a time, creating new businesses that will improve people’s lives and transform the world.
                    <br />
                    <br />
                  </p>
                
                   </div>
              </div>
            </div>
          
          </section>


 

          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  {/* <h3>
                    We ready to help
                    </h3> */}

                  <h3>  A&nbsp;DEVELOPMENT PARTNER TO VISIONARIES</h3>

                  <p>     
                  
                  We create and in some cases partner with select founders
                  to create new products and services, and build teams to
                  scale them as independent companies helping to define,
                  create and grow next generation technology and solutions.     <br />
                    <br />
                  </p>
                
                   </div>
              </div>
            </div>
          
          </section>  
<div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  What are your biggest vision? We'd like to help.  
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/">Let's talk</a>
              </div>
            </div>
          </div>
     
        </Layout>
      </>
    )}
  />
)


export default BusinessPage
